<br>
<div class="cotainer">
    <div class="row justify-content-center">
        <div class="col-md-8">
            <div class="card">
                <div class="card-header">
                    <h1>Payment Receipt</h1>
                </div>
                <div class="card-body">
                    <form action="" method="">
                        <div class="form-group row" *ngIf='transDate'>
                            <label for="transDate" class="col-md-12 col-form-label text-md-center"><strong>Transaction Date:- {{transDate}}</strong></label>
                            <!-- <div class="col-md-6">
                                <input type="text" id="transDate" class="form-control" [value]="transDate" name="transDate" disabled autofocus>
                            </div> -->
                        </div>
                        <div class="form-group row" *ngIf='tranid'>
                            <label for="transId" class="col-md-4 col-form-label text-md-right">Transaction Id</label>
                            <div class="col-md-6">
                                <input type="text" id="transId" class="form-control" [value]="tranid" name="transId" disabled autofocus>
                            </div>
                        </div>

                        <div class="form-group row" *ngIf='cardToken'>
                            <label for="cardToken" class="col-md-4 col-form-label text-md-right">Card Token</label>
                            <div class="col-md-6">
                                <input type="text" id="cardToken" class="form-control" [value]="cardToken" name="cardToken" disabled>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="amount" class="col-md-4 col-form-label text-md-right">Order Amount</label>
                            <div class="col-md-6">
                                <input type="text" id="amount" class="form-control" [value]="amount" name="amount" disabled>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="card-footer">
                    <h1>Status</h1>
                    <div class="alert {{alertType}}" role="alert">
                        <strong>{{result}}</strong>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>