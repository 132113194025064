import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { ReactiveFormsModule, UntypedFormBuilder } from '@angular/forms';

import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {PanelModule} from 'primeng/panel';
import { HttpClientModule } from '@angular/common/http';
import { PluginService } from './service/pluginservice.service';
import {RadioButtonModule} from 'primeng/radiobutton';
import { FormsModule } from '@angular/forms';
import { PrintErrorMessageComponent } from './print-error-message/print-error-message.component';
// import {InputNumberModule} from 'primeng/inputnumber';
// import {InputTextModule} from 'primeng/inputtext';
import {KeyFilterModule} from 'primeng/keyfilter';
import { PaymentRecieptComponent } from './payment-reciept/payment-reciept.component';
import { MerchantFormComponent } from './merchant-form/merchant-form.component';
import { DebounceClickDirective } from './debounce-click.directive';
import { DatePipe } from '@angular/common';
@NgModule({
  declarations: [
    AppComponent,
    PrintErrorMessageComponent,
    PaymentRecieptComponent,
    MerchantFormComponent,
    DebounceClickDirective
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    PanelModule,
    HttpClientModule,
    RadioButtonModule,
    FormsModule,
    KeyFilterModule,
  ],
  providers: [UntypedFormBuilder,PluginService,DatePipe],
  bootstrap: [AppComponent]
})
export class AppModule { }
