<html>
<div>
<iframe [src]='safeUrl' frameBorder="0" allowfullscreen></iframe>
</div>

<form name="myform" method="POST" action="'.$url.'">
    <h1> Transaction is processing........</h1> 
    
    </form>
    <script type="text/javascript">document.myform.submit();</script>
</html>
