import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ResponseCode } from '../constant/response-code';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-print-error-message',
  templateUrl: './print-error-message.component.html',
  styleUrls: ['./print-error-message.component.css']
})
export class PrintErrorMessageComponent implements OnInit {
 
  amount= ""
  cardToken= ""
  responseCode= ""
  responseHash= ""
  result= ""
  tranid= ""
  transDate=""
  alertType=""
  currentDate=Date.now()+""
  constructor(private route: ActivatedRoute,private datePipe: DatePipe) { 
    this.route.queryParams.subscribe(params=>
      {
        this.amount=params['amount']
        this.cardToken=params['cardToken']
        this.responseCode=params['responseCode']
        this.responseHash=params['responseHash']
        this.result=ResponseCode.response_msg[params['responseCode']]
        this.tranid=params['tranid']
        if(params['trandate'])
        this.transDate=params['trandate']
        else
          this.transDate=this.datePipe.transform(new Date(), 'yyyy-MM-dd, HH:mm');

      })
      
  }

  ngOnInit(): void {
    localStorage.setItem('doubleClick', "true");
    if(this.responseCode==="000"){
      this.alertType='alert-success'
    }else
    {
      this.alertType='alert-danger'
    }
  }
  
}
