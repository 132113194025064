export class ValidateMerchant{

    errorMessage=''
    isError: boolean
    isCountryCode=false
    isFirstName=false 
    isLastName=false 
    isAddress=false 
    isCity=false 
    isState=false 
    isZipCode=false 
    isPhoneNumber=false 
    isEmail=false 
    isResURL=false 
    isUdf3=false 
    isAmount=false 
    isOrderID=false 
    isTransactionID=false 
    isCurrency=false 
    isActionCode=false
    isTokenOperation=false
    isCardToken=false 
    isCustomerPresent=false 
}