import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  
  //  regexp = new RegExp(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)
  constructor(private http: HttpClient) { }

  ngOnInit() {
    // console.log("email validate : ",this.regexp.test("amit@.gmail.com"))
    localStorage.setItem('doubleClick', "true");
    // console.log(environment.production); // Logs false for default environment
  }
   
  
}
