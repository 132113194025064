import {Component, OnInit} from '@angular/core';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';


@Component({
    selector: 'App-embed-form',
    templateUrl : './embed-form.component.html',
    styleUrls : ['./embed-form.component.css']
})

export class EmbedFormComponent implements OnInit {
    safeUrl: SafeResourceUrl;
     constructor(private _sanitizer : DomSanitizer) {}

    ngOnInit() {
        this.safeUrl =this._sanitizer.bypassSecurityTrustResourceUrl('https://payments-dev.urway-tech.com/URWAYPGService/direct.jsp?paymentid=2300312463900099972');
    }
}

function ngOnInit() {
    throw new Error('Function not implemented.');
}
