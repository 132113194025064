import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, FormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { PluginService } from '../service/pluginservice.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ValidateMerchant } from '../service/validate-merchant';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
@Component({
  selector: 'app-merchant-form',
  templateUrl: './merchant-form.component.html',
  styleUrls: ['./merchant-form.component.css'],
})
export class MerchantFormComponent implements OnInit {
  btnClickCounter = 0;
  merchantForm: UntypedFormGroup;
  isMerchantOrReceipt=true;
  submitted = false;
  isDoubleClick=true;
  isResetForm=true;
  validForm:ValidateMerchant
  safeUrl: SafeResourceUrl = ''

 getTokenOperation="A"
  data = [
    {
      id: 1,operation: 'Add',operationcode: 'A'
    },
    {
      id: 2,operation: 'Update',operationcode: 'U'
    },
    {
      id: 3,operation: 'Delete',operationcode: 'D'
    },
  ];
  getActionCode='1'
  actiondata = [
    {
      id: 1,operation: 'Purchase',operationcode: '1'
    },
    {
      id: 2,operation: 'Pre-Authorization',operationcode: '4'
    },
    {
      id: 3,operation: 'Refund Transaction',operationcode: '2'
    },
    {
      id: 4,operation: 'Capture Transaction',operationcode: '5'
    },
    {
      id: 5,operation: 'Void Purchase',operationcode: '3'
    },
    {
      id: 6,operation: 'Void Authorization',operationcode: '9'
    },
    {
      id: 7,operation: 'Tokenization',operationcode: '12'
    },
    {
      id: 8,operation: 'Transaction Inquiry',operationcode: '10'
    },
    {
      id: 9,operation: 'Void Refund',operationcode: '6'
    }
  ];
 
  constructor(private formBuilder: UntypedFormBuilder,private services: PluginService,private _sanitizer : DomSanitizer) { }

  ngOnInit() {
    console.log("doubleClick : ",localStorage.getItem('doubleClick'))
    this.validForm=new ValidateMerchant()
      this.merchantForm = this.formBuilder.group({
        countryCode: ['', [Validators.required, Validators.maxLength(2),Validators.minLength(2),
        Validators.pattern('^[a-zA-Z]+$')]],
          firstName: ['', [Validators.pattern('^[a-zA-Z]+$')]],
          lastName: ['', [Validators.pattern('^[a-zA-Z]+$')]],
          address: [''],
          city: ['', [Validators.pattern('^[a-zA-Z]+$')]],
          state: ['', [Validators.pattern('^[a-zA-Z]+$')]],
          zipCode: ['', [Validators.pattern('^[0-9]+$')]],
          phoneNumber: ['', [Validators.pattern('^[0-9]+$')]],
          email: ['', [Validators.required,Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$')]],
          resURL: [''],
          udf3: [''],
          udf5: [''],
          amount: ['', [Validators.required,Validators.min(1)]],
          orderID: ["",[Validators.required,Validators.maxLength(20)]],
          transactionID: [""],
          currency: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(3)]],
          actionCode: ['1', [Validators.required,Validators.maxLength(2)]],
          tokenOperation: [""],
          cardToken: [""],
          CustomerPresent: ["",Validators.required],
         
      });
      this.merchantForm.controls['CustomerPresent'].setValue("0")
  }
  tokenOperationSelected(value:string){
      this.getTokenOperation=value;
  }
  actionSelected(value:string){
      this.getActionCode=value;
  }
  // convenience getter for easy access to form fields
  get f() { return this.merchantForm.controls; }

  async onSubmit() {
    if(localStorage.getItem('doubleClick')==="true")
    {
      localStorage.setItem('doubleClick', "false");
      if(this.getActionCode !== "12")
      {
        this.getTokenOperation=""
        this.merchantForm.controls['tokenOperation'].setValue(this.getTokenOperation)
      }  
      else
        this.merchantForm.controls['tokenOperation'].setValue(this.getTokenOperation)
        var setCurrency=(""+this.merchantForm.controls['currency'].value).toUpperCase()
        
      localStorage.setItem('getCurrency', setCurrency);
      this.merchantForm.controls['actionCode'].setValue(this.getActionCode)
      this.submitted = true;
      if (this.merchantForm.invalid) {
        localStorage.setItem('doubleClick', "true");
        console.log("invalid form*******")
      }
      this.validForm=Object(this.services.validate(JSON.stringify(this.merchantForm.value))) as ValidateMerchant
      if(!this.validForm.isError)
        this.safeUrl = await this.services.postData(JSON.stringify(this.merchantForm.value));
        this.reset();
    }
  }
  search() {
    this.btnClickCounter += 1;
  }
  reset() {
    if(this.merchantForm.valid && !this.validForm.isError){
      this.isResetForm=false;
      this.merchantForm.reset();
      this.merchantForm.controls['CustomerPresent'].setValue("0")
    }
    
    
  }

}

