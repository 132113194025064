import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PaymentRecieptComponent } from './payment-reciept/payment-reciept.component';
import { MerchantFormComponent } from './merchant-form/merchant-form.component';
import { PrintErrorMessageComponent } from './print-error-message/print-error-message.component';
import { EmbedFormComponent } from './iframe/embed.-form.component';


const routes: Routes = [
  {path: '', redirectTo: 'merchantForm', pathMatch: 'full'},
   {path: 'merchantForm', component: MerchantFormComponent},
   {path: 'iframe', component: EmbedFormComponent},
   {path: 'payment-receipt', component: PaymentRecieptComponent},
   {path: 'payment-status', component: PrintErrorMessageComponent}
 ];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
