import { Component, OnInit } from '@angular/core';
import { ResponseCode } from '../constant/response-code';
import { DatePipe } from '@angular/common';
import * as configJson from 'src/assets/configuration.json'
import { PluginService } from '../service/pluginservice.service';
@Component({
  selector: 'app-payment-reciept',
  templateUrl: './payment-reciept.component.html',
  styleUrls: ['./payment-reciept.component.css']
})
export class PaymentRecieptComponent implements OnInit {
  urlParameters:Map<string,string>
  transDate=''
  transId=''
  cardToken=''
  orderAmount=''
  status=''
  alertType=''
  currentDate=Date.now()+""
  isSuccess= false
  constructor(private datePipe: DatePipe,private services: PluginService) { }

  ngOnInit(): void {
    localStorage.setItem('doubleClick', "true");
    this.GetURLParameter()
  }
  GetURLParameter() {
    this.urlParameters = new Map<string, string>();
    if (window.location.search.substring(1).length != 0) {
      var sPageURL = window.location.search.substring(1);
      var sURLVariables = sPageURL.split("&");
      for (var i = 0; i < sURLVariables.length; i++) {
        var sParameterName = sURLVariables[i].split("=");
        this.urlParameters.set(""+sParameterName[0].split(","),""+sParameterName[1].split(","))
      }
      var reqHashSequence= this.urlParameters.get("TranId") + "|" + configJson.default.merchantkey + "|" + this.urlParameters.get("ResponseCode") + "|" + this.urlParameters.get("amount");
      var reqHash = this.services.generateHash(reqHashSequence)
      var setRequest = {
        "firstName": "",
        "lastName": "",
        "address": "",
        "city": "",
        "state": "",
        "zipCode": "",
        "phoneNumber": "",
        "trackid": this.urlParameters.get("TrackId"),
        "terminalId": configJson.default.terminalId,
        "customerEmail": "",
        "action": '10',
        "merchantIp": "",
        "password": configJson.default.password,
        "currency": localStorage.getItem('getCurrency'),
        "country": "",
        "transid": this.urlParameters.get("TranId"),
        "amount": Number(this.urlParameters.get("amount")),
        "tokenOperation": "",
        "cardToken": "",
        "requestHash": "",
        "udf1": "",
        "udf2": "",
        "udf3": "",
        "udf4": "",
        "udf5": ""
    }
      if(this.urlParameters.get("responseHash")+"" === reqHash+""){
        var responseHashSequence = this.urlParameters.get("TrackId")+"|"+configJson.default.terminalId+"|"+configJson.default.password+"|"+configJson.default.merchantkey+"|"+Number(this.urlParameters.get("amount"))+"|"+localStorage.getItem('getCurrency');
        var responseHash = this.services.generateHash(responseHashSequence)
        setRequest['requestHash']=responseHash
        if(this.urlParameters.get("transDate"))
          this.currentDate=(this.urlParameters.get("transDate"))
        else
          this.currentDate=this.datePipe.transform(new Date(), 'yyyy-MM-dd, HH:mm');
        this.services.paymentAPI(JSON.stringify(setRequest)).subscribe(
          res=>{
          let getResponse=JSON.parse(JSON.stringify(res));
            if(getResponse['responseCode']+"" === this.urlParameters.get('ResponseCode')){
              this.transDate=this.urlParameters.get("transDate")
              this.transId=this.urlParameters.get("TranId")
              if((this.urlParameters.get("cardToken")+"")=='null')
              {
                this.cardToken=""
              }else if(this.urlParameters.get("cardToken")==undefined)
                this.cardToken=""
              else
                this.cardToken=""+this.urlParameters.get("cardToken")
              
            this.orderAmount=this.urlParameters.get("amount")
            if(this.urlParameters.get("ResponseCode")==="000")
              this.alertType='alert-success'
              else
              this.alertType='alert-danger'
            this.status=ResponseCode.response_msg[this.urlParameters.get("ResponseCode")]
            }
            else{
              this.transDate=this.urlParameters.get("transDate")
              this.transId=this.urlParameters.get("TranId")
              if((this.urlParameters.get("cardToken")+"")=='null')
              {
                this.cardToken=""
              }else if(this.urlParameters.get("cardToken")==undefined)
                this.cardToken=""
              else
                this.cardToken=""+this.urlParameters.get("cardToken")
            this.orderAmount=this.urlParameters.get("amount")
              if(getResponse['responseCode']==="000")
              this.alertType='alert-success'
              else
              this.alertType='alert-danger'
              this.status=ResponseCode.response_msg[getResponse['responseCode']]
            }
            
          }
        );
        
      }else{
        this.isSuccess=true
      }

     
    }
  }

}
