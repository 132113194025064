<!-- main app container -->

<div class="jumbotron">
    <div class="container">
        <div style="width:100%;text-align: center;">
            <img id="bg" src="https://payments.urway-tech.com/URWAYPG/Images/logo.png" alt="Stickman" width="300px" height="150px">
        </div>
        <div class="p-grid ">
            <form [formGroup]="merchantForm" (ngSubmit)="onSubmit()" #formDirective="ngForm" class="p-col-12">
                <div style="text-align: center;">
                <iframe [src]='safeUrl' frameBorder="0" allowfullscreen width="1200" height="380"></iframe>
</div>
                <div class="p-grid">
                    <div class="p-col-6">
                        <div class="p-grid">
                            <div class="p-col-12">
                                <div class="form-group required">
                                    <label class="control-label">Country Code</label>
                                    <input type="text" style="text-transform:uppercase" pKeyFilter="alpha" maxlength=2 formControlName="countryCode" class="form-control" [ngClass]="isResetForm?{ 'is-invalid': submitted && f.countryCode.errors }:''" />
                                    <div *ngIf="isResetForm && this.validForm.isCountryCode"><label class="hasError">{{this.validForm.errorMessage}}</label></div>
                                    <div *ngIf="isResetForm && submitted && f.countryCode.errors && !this.validForm.isCountryCode" class="invalid-feedback">
                                        <div *ngIf="f.countryCode.errors.required">Country Code is required</div>
                                        <div *ngIf="f.countryCode.errors.maxlength">Country Code Length Should be Two</div>
                                        <div *ngIf="f.countryCode.errors.minlength">Country Code Length Should be Two</div>

                                        <div *ngIf="f.countryCode.errors.pattern">Country Code Invalid</div>
                                    </div>
                                    <!-- <label *ngIf="merchantForm.controls['countryCode'].hasError('minlength')">Use 6 or more characters with a mix of letters</label> -->
                                </div>
                                <div class="form-group">
                                    <label>First Name</label>
                                    <input type="text" pKeyFilter="alpha" maxlength=30 formControlName="firstName" class="form-control" [ngClass]="isResetForm?{ 'is-invalid': submitted && f.firstName.errors }:''" />
                                    <div *ngIf="submitted && f.firstName.errors" class="invalid-feedback">
                                        <div *ngIf="f.firstName.errors.pattern">Only Alphabets Allowed</div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label>Last Name</label>
                                    <input type="text" pKeyFilter="alpha" maxlength=30 formControlName="lastName" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.lastName.errors }" />
                                    <div *ngIf="submitted && f.lastName.errors" class="invalid-feedback">
                                        <div *ngIf="f.lastName.errors.pattern">Only Alphabets Allowed</div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label>Address</label>
                                    <input type="text" formControlName="address" maxlength=50 class="form-control" [ngClass]="{ 'is-invalid': submitted && f.address.errors }" />
                                    <!-- <div *ngIf="submitted && f.address.errors" class="invalid-feedback">
                                                <div *ngIf="f.address.errors.required">Address is required</div>
                                            </div> -->
                                </div>
                                <div class="form-group">
                                    <label>City</label>
                                    <input type="text" pKeyFilter="alpha" maxlength=30 formControlName="city" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.city.errors }" />
                                    <div *ngIf="submitted && f.city.errors" class="invalid-feedback">
                                        <div *ngIf="f.city.errors.pattern">Only Alphabets Allowed</div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label>State</label>
                                    <input type="text" pKeyFilter="alpha" maxlength=30 formControlName="state" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.state.errors }" />
                                    <div *ngIf="submitted && f.state.errors" class="invalid-feedback">
                                        <div *ngIf="f.state.errors.pattern">Only Alphabets Allowed</div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label>Zip / Postal Code</label>
                                    <input type="text" pKeyFilter="pint" maxlength=6 formControlName="zipCode" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.zipCode.errors }" />
                                    <div *ngIf="submitted && f.zipCode.errors" class="invalid-feedback">
                                        <div *ngIf="f.zipCode.errors.pattern">Only Digits Allowed</div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label>Phone Number</label>
                                    <input type="text" pKeyFilter="pint" maxlength=10 formControlName="phoneNumber" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.phoneNumber.errors }" />
                                    <!-- <div *ngIf="submitted && f.phoneNumber.errors" class="invalid-feedback">
                                                <div *ngIf="f.phoneNumber.errors.required">Country Code is required</div>
                                            </div> -->

                                </div>
                                <div class="form-group required">
                                    <label class="control-label">Email</label>
                                    <input type="email" pKeyFilter="email" maxlength=30 formControlName="email" class="form-control" [ngClass]="isResetForm?{ 'is-invalid': submitted && f.email.errors }:''" />
                                    <div *ngIf="isResetForm && this.validForm.isEmail"><label class="hasError">{{this.validForm.errorMessage}}</label></div>
                                    <div *ngIf="isResetForm && submitted && f.email.errors && !this.validForm.isEmail" class="invalid-feedback">
                                        <div *ngIf="f.email.errors.required">Email is required</div>
                                        <!-- <div *ngIf="f.email.errors.email">Email must be a valid email address</div> -->
                                        <div *ngIf="f.email.errors.pattern">Email must be a valid email address</div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label>Response URL:(udf2)</label>
                                    <input type="text" formControlName="resURL" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.resURL.errors }" />

                                </div>
                                <div class="form-group">
                                    <label>Language:(udf3)</label>
                                    <input type="text" formControlName="udf3" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.udf3.errors }" />

                                </div>
                                <div class="form-group">
                                    <label>udf5</label>
                                    <input type="text" formControlName="udf5" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.udf5.errors }" />

                                </div>


                            </div>

                        </div>
                    </div>
                    <div class="p-col-6">
                        <div class="p-grid ">
                            <div class="p-col-12">
                                <div class="form-group required">
                                    <label class="control-label ">Amount</label>
                                    <input type="text" pInputText pKeyFilter="money" maxlength=10 formControlName="amount" class="form-control" [ngClass]="isResetForm?{ 'is-invalid': submitted && f.amount.errors }:''" />
                                    <div *ngIf="isResetForm && this.validForm.isAmount"><label class="hasError">{{this.validForm.errorMessage}}</label></div>
                                    <div *ngIf="isResetForm && submitted && f.amount.errors && !this.validForm.isAmount" class="invalid-feedback">
                                        <div *ngIf="f.amount.errors.required">Amount is required</div>
                                        <!-- <div *ngIf="isError">msg: </div> -->
                                        <div *ngIf="f.amount.errors.min">Enter Valid Amount</div>
                                    </div>
                                </div>
                                <div class="form-group required">
                                    <label class="control-label">Order ID</label>
                                    <input type="text" formControlName="orderID" maxlength=30 class="form-control" [ngClass]="isResetForm?{ 'is-invalid': submitted && f.orderID.errors }:''" />
                                    <div *ngIf="isResetForm && this.validForm.isOrderID"><label class="hasError">{{this.validForm.errorMessage}}</label></div>
                                    <div *ngIf="isResetForm && submitted && f.orderID.errors && !this.validForm.isOrderID" class="invalid-feedback">
                                        <div *ngIf="f.orderID.errors.required">Order ID is required</div>
                                        <div *ngIf="f.orderID.errors.maxlength">Max Length should be less then 20</div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label>Transaction ID(For refund/capture/inquiry/void)</label>
                                    <input type="text" formControlName="transactionID" maxlength=30 class="form-control" [ngClass]="{ 'is-invalid': submitted && f.transactionID.errors }" />
                                    <div *ngIf="submitted && f.transactionID.errors" class="invalid-feedback">
                                        <div *ngIf="f.transactionID.errors.required">transactionID is required</div>
                                    </div>
                                </div>
                                <div class="form-group required">
                                    <label class="control-label">Currency:</label>
                                    <input type="text" style="text-transform:uppercase" formControlName="currency" pKeyFilter="alpha" maxlength=3 class="form-control" [ngClass]="isResetForm?{ 'is-invalid': submitted && f.currency.errors }:''" />
                                    <div *ngIf="isResetForm && this.validForm.isCurrency"><label class="hasError">{{this.validForm.errorMessage}}</label></div>
                                    <div *ngIf="isResetForm && submitted && f.currency.errors && !this.validForm.isCurrency" class="invalid-feedback">
                                        <div *ngIf="f.currency.errors.required">currency is required</div>
                                        <div *ngIf="f.currency.errors.minlength">Length should be 3</div>
                                        <div *ngIf="f.currency.errors.maxlength">Length should be 3</div>
                                    </div>
                                </div>
                                <div class="form-group ">
                                    <label>Action</label>
                                    <!-- <input type="text" formControlName="actionCode" pKeyFilter="pint" maxlength=2 class="form-control" [ngClass]="{ 'is-invalid': submitted && f.actionCode.errors }" />
                                            <div *ngIf="submitted && f.actionCode.errors" class="invalid-feedback">
                                                <div *ngIf="f.actionCode.errors.required">actionCode is required</div>
                                                <div *ngIf="f.actionCode.errors.maxlength">Maximum Length should be 2</div>
                                            </div> -->
                                    <select name="tokenOperation" id="tokenOperation" class="form-control" #actionSelect (change)='actionSelected(actionSelect.value)'>
                                                    <option *ngFor="let item of actiondata" [value]="item.operationcode" >{{item.operation}}</option>
                                            </select>
                                </div>
                                <div class="form-group">
                                    <label>Token Operation</label>
                                    <select name="tokenOperation" id="tokenOperation" class="form-control" #mySelect (change)='tokenOperationSelected(mySelect.value)'>
                                                    <option *ngFor="let item of data" [value]="item.operationcode" >{{item.operation}}</option>
                                            </select>
                                </div>
                                <div class="form-group">
                                    <label>Card Token</label>
                                    <input type="text" formControlName="cardToken" maxlength=30 class="form-control" [ngClass]="{ 'is-invalid': submitted && f.cardToken.errors }" />
                                    <!-- <div *ngIf="submitted && f.cardToken.errors" class="invalid-feedback">
                                                <div *ngIf="f.cardToken.errors.required">cardToken is required</div>
                                            </div> -->

                                </div>
                                <div class="form-group">

                                    <div class="form-check" checked>
                                        <label class="form-check-label" for="radio1">
                                                  <input type="radio" class="form-check-input " id="radio1" formControlName="CustomerPresent" value="0" [checked]="true">Customer Present
                                                </label>
                                    </div>
                                    <div class="form-check">
                                        <label class="form-check-label" for="radio2">
                                                  <input type="radio" class="form-check-input" id="radio2" formControlName="CustomerPresent" value="1">Customer Not Present
                                                </label>
                                    </div>
                                    <div *ngIf="submitted && f.CustomerPresent.errors" class="invalid-feedback">
                                        <div *ngIf="f.CustomerPresent.errors.required">Please Select Value</div>

                                    </div>

                                </div>




                            </div>

                        </div>

                    </div>
                </div>
                <div class="form-group">
                    <button class="btn btn-primary" appDebounceClick (debounceClick)="onSubmit()" [debounceTime]="200">Submit</button>
                </div>

            </form>
        </div>
    </div>
</div>


<!-- *********************************************** -->